<template>
  <div id="mobile">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Mobile',
};
</script>

<style lang="scss">
@import '../styles/styles.scss';
@import '../styles/theme/_mobile.scss';

#mobile {
  color: $body-color;
  margin-top: 15px;
}
</style>
